import React, { Component } from "react";
import { Grid, Card, Image, Item, Modal } from "semantic-ui-react";
import Styles from "../Styles/ScreenComponentStyles";
import moment from "moment";
import { Images } from "../../themes";
import Slider from "react-slick";
import QRCode from "qrcode.react";
import _ from "lodash";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class ScreenComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      event: null
    };

    this.sliderSettings = {
      lazyLoad: [0, 5],
      dots: false,
      infinite: true,
      speed: 1000,
      arrows: false,
      draggable: false,
      vertical: false,
      autoplay: true,
      pauseOnHover: false,
      fade: true,
      cssEase: "easeOutElastic",
      autoplaySpeed: 5000,
      adaptiveHeight: true
    };
  }

  componentDidMount() {
    if (this.props.event) {
      this.setState({ event: this.props.event });
    }

    if (this.props.match && this.props.match.params.id) {
      this.props.getRoom(this.props.match.params.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.event) {
      this.setState({ event: nextProps.event });
    }

    if (this.props.room) {
      if (!this.props.room.room && nextProps.room.room) {
        this.setState({ event: nextProps.room.room.current_event });
      }
    }
  }

  renderPromosAndMessages() {
    const messagesToDisplay = this.state.event.event_messages.filter(m => {
      return (
        !m.is_device_blacklisted &&
        !m.is_message_blacklisted &&
        m.deleted_at == undefined
      );
    });

    const messagesToDisplayElements = messagesToDisplay.map(message => {
      return (
        <div style={Styles.commentCard} key={message.id}>
          {message.image_url ? (
            <Image src={Images.remote(message.image_url)} />
          ) : (
            <Image src={Images.pattern} />
          )}

          <div style={Styles.overlayContainer}>
            <div
              style={
                message.content
                  ? Styles.avatarContainer
                  : Styles.avatarContainerSingle
              }
            >
              <Image
                src={message.provider_picture_url}
                size="tiny"
                circular
                style={{ width: 40, height: 40 }}
              />
              <div style={Styles.avatarName}>{message.name}</div>
            </div>
            {!this.state.event.image_only && message.content && (
              <div style={Styles.commentCardText}>
                {message.is_private
                  ? "Ha dejado un mensaje privado"
                  : message.content}
              </div>
            )}
          </div>
        </div>
      );
    });

    const promosToDisplay = this.props.promos.filter(
      p => p.deleted_at == undefined
    );
    const promoContentsToDisplay = _.flatten(
      promosToDisplay.map(p => p.promo_contents)
    );

    const promoContentsToDisplayElements = promoContentsToDisplay.map(pc => {
      return (
        <div style={Styles.promoCard}>
          {pc.is_video ? (
            <video autoplay="autoplay" loop style={Styles.promoImageCard}>
              <source src={pc.file_preview_url}></source>
            </video>
          ) : (
            <Image
              src={Images.remote(pc.file_preview_url)}
              style={Styles.promoImageCard}
            />
          )}
        </div>
      );
    });

    // Si no hay mensajes del público, sólo mostrar promociones.
    if (messagesToDisplayElements.length === 0) {
      return promoContentsToDisplayElements;
    }

    // Si no hay promociones, sólo mostrar mensajes.
    if (promoContentsToDisplayElements.length === 0) {
      return messagesToDisplayElements;
    }

    // Si hay mensajes del público y promociones, intercalar.
    const interleave = ([x, ...xs], ys = []) =>
      x === undefined ? ys : [x, ...interleave(ys, xs)];

    return interleave(
      messagesToDisplayElements,
      promoContentsToDisplayElements
    );
  }

  render() {
    const event = this.state.event;
    const plan = event && event.company.display_messages;

    const getStyleScreen = plan =>
      plan ? Styles.leftContent : Styles.singleContent;
    const getStyleCircle = plan =>
      Object.assign({}, Styles.imgCircle, !plan && Styles.imgCircleBasic);
    const getStyleReligionCircle = plan =>
      Object.assign(
        {},
        Styles.religionCircle,
        !plan && Styles.religionCircleBasic
      );
    const getStyleEventCardName = plan =>
      Object.assign(
        {},
        Styles.eventCardName,
        !plan && Styles.eventCardNameBasic
      );
    const getStyleEventCardDates = plan =>
      Object.assign(
        {},
        Styles.eventCardDates,
        !plan && Styles.eventCardDatesBasic
      );
    const getStyleEventCardBurialDate = plan =>
      Object.assign(
        {},
        Styles.eventCardBurialDate,
        !plan && Styles.eventCardBurialDateBasic
      );

    if (!event) {
      return null;
    }

    const promosToDisplay = this.props.promos.filter(
      p => p.deleted_at == undefined
    );
    const promoContentsToDisplay = _.flatten(
      promosToDisplay.map(p => p.promo_contents)
    );

    return (
      <Grid centered columns={2} style={Styles.screenContent}>
        <Grid.Column width={6} style={getStyleScreen(plan)}>
          {plan && event.display_code_in_room && (
            <div style={Styles.container}>
              <div style={Styles.instructionsContainer}>
                <div style={Styles.instructions}>
                  Escaneá el QR <br /> y subí tu foto
                </div>
                <div style={Styles.instructionsText}>
                  Compartí tus mejores momentos de
                  la noche.
                </div> 
              </div>
              <div style={Styles.logos}>
                <div style={Styles.qrContainer}>
                  <QRCode
                    size={178}
                    value={`${window.location.origin}/enviar?eventCode=${event.code}`}
                  />
                </div>
                <Image
                  src={Images.remote(event.company.logo_url)}
                  style={Styles.logo}
                />
                <Image src={Images.logo} style={Styles.logo} />
              </div>
            </div>
          )}
        </Grid.Column>
        {plan && (
          <Grid.Column width={10} style={Styles.rightContent}>
            <div style={Styles.rightContainer}>
              {(this.state.event.event_messages.filter(m => {
                return (
                  !m.is_device_blacklisted &&
                  !m.is_message_blacklisted &&
                  m.deleted_at == undefined
                );
              }).length > 0 ||
                promoContentsToDisplay.length > 0) && (
                <Slider {...this.sliderSettings}>
                  {this.renderPromosAndMessages()}
                </Slider>
              )}
            </div>
          </Grid.Column>
        )}
      </Grid>
    );
  }
}

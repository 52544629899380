import { Colors, Images } from "../../themes";

export default {
  content: {
    backgroundImage: "url(" + Images.bgWeb + ")",
    backgroundSize: "cover",
    minHeight: "100vh",
    margin: 0,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "@media only screen and (min-width: 480px)": {
      backgroundImage: "url(" + Images.bgMobile + ")"
    }
  },
  cardContent: {},
  logo: {
    width: 150,
    margin: 15
  },
  message: {
    textAlign: "left",
    marginBottom: 15
  },
  card: {
    padding: "1.5em",
    border: "none",
    borderRadius: 20,
    boxShadow: "none"
  },
  messageTitle: {
    marginBottom: 5,
    color: Colors.primary,
    fontSize: 22,
    fontWeight: "600"
  },
  messageText: {
    color: Colors.secondary,
    fontSize: 18,
    marginBottom: 20
  },
  cardButton: {
    backgroundColor: Colors.primary,
    color: Colors.snow,
    borderRadius: 50,
    marginTop: 15
  },
  cardButtonFacebook: {
    backgroundColor: "#3a5793",
    color: Colors.snow,
    borderRadius: 50,
    marginTop: 15
  },
  cardButtonGoogle: {
    backgroundColor: "#e34134",
    color: Colors.snow,
    borderRadius: 50,
    marginTop: 15
  },
  cardButtonApple: {
    backgroundColor: "black",
    color: Colors.snow,
    borderRadius: 50,
    marginTop: 15
  },
  cardButtonInstagram: {
    marginTop: 15,
    borderRadius: 50,
    color: Colors.snow,
    background: "#d6249f",
    background:
      "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
    boxShadow: "0px 3px 10px rgba(0,0,0,.25)"
  },
  cancelButton: {
    backgroundColor: Colors.gray,
    color: Colors.snow,
    borderRadius: 50,
    marginTop: 15
  },
  eventHeader: {
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightgray,
    paddingTop: 10,
    paddingBottom: 10
  },
  eventName: {
    fontSize: 32,
    color: Colors.charcoal,
    marginBottom: 5,
    lineHeight: 1
  },
  eventDates: {
    fontSize: 16,
    color: Colors.gray
  },
  inlineRadios: {
    display: "none",
    justifyContent: "center",
    alignItems: "center"
  },
  logosContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 20
  },
  logos: {
    display: "flex",
    width: "40%",
    maxHeight: 80,
    objectFit: "contain",
    margin: "0 15px"
  },
  modalIcon: {
    color: "#fff"
  },
  buttonIcon: {
    color: Colors.snow,
    marginRight: 5
  },
  textButton: {
    marginTop: 10,
    marginRight: 0,
    backgroundColor: "transparent",
    color: Colors.secondary
  }
};

import React, { Component } from "react";
import { Grid, Card, Image, Item, Loader, Dimmer } from "semantic-ui-react";
import Styles from "../Styles/ScreenComponentStyles";
import { Images } from "../../themes";
import BranchScreenComponent from "./BranchScreenComponent";
import ScreenComponent from "./ScreenComponent";
import queryString from "query-string";

const config = require("../../config");
const node_env = process.env.NODE_ENV || "development";

const wsUrl = config[node_env]["wsUrl"];

const ActionCable = require("actioncable");

export default class ActivateScreenComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      branch: null,
      event: null,
      promos: []
    };
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    const { roomCode, branchId } = params;

    if (roomCode) {
      this.props.activateScreen(roomCode);
      return;
    }

    if (branchId) {
      this.props.getBranchById(branchId);
      return;
    }

    this.initializeReceiver();
  }

  componentWillUnmount() {
    this.cable.subscriptions.remove(this.subscription);
  }

  initializeReceiver() {
    window.cast.receiver.logger.setLevelValue(0);
    window.castReceiverManager = window.cast.receiver.CastReceiverManager.getInstance();

    window.messageBus = window.castReceiverManager.getCastMessageBus(
      "urn:x-cast:com.google.cast.codingways.nitpoint",
      window.cast.receiver.CastMessageBus.MessageType.JSON
    );

    window.messageBus.onMessage = event => {
      const { roomCode, branchId } = event.data;

      if (roomCode) {
        this.props.activateScreen(roomCode);
      } else {
        this.props.getBranchById(branchId);
      }
      window.messageBus.send(event.senderId, event.data);
    };

    window.castReceiverManager.start({ statusText: "Nitpoint" });
  }

  subscribeToRoom(roomDevice) {
    this.cable = ActionCable.createConsumer(wsUrl);

    this.subscription = this.cable.subscriptions.create(
      {
        channel: "RoomChannel",
        device_fingerprint: roomDevice.device_fingerprint
      },
      {
        received: data => {
          if (data.event) {
            this.setState({
              event: { ...this.state.event, ...data.event }
            });
          } else if (data.message) {
            let messages = this.state.event.event_messages;

            const messageExists = messages.some(m => m.id === data.message.id);

            if (messageExists) {
              messages = messages.map(m => {
                if (m.id === data.message.id) {
                  return data.message;
                }

                return m;
              });
            } else {
              messages = messages.concat(data.message);
            }

            if (this.state.event.messages_limit) {
              messages = messages.slice(-this.state.event.messages_limit);
            }

            this.setState({
              event: { ...this.state.event, event_messages: messages }
            });
            return;
          } else if (data.promo) {
            let promos = this.state.promos;

            const promoExists = promos.some(p => p.id === data.promo.id);

            if (promoExists) {
              promos = promos.map(p => {
                if (p.id === data.promo.id) {
                  return data.promo;
                }

                return p;
              });
            } else {
              promos = promos.concat(data.promo);
            }

            this.setState({
              promos
            });
          } else {
            this.setState({ event: null });
          }
        }
      }
    );
  }

  subscribeToBranch(branch) {
    this.cable = ActionCable.createConsumer(wsUrl);

    this.subscription = this.cable.subscriptions.create(
      {
        channel: "BranchChannel",
        id: branch.id
      },
      {
        received: data => {
          if (data.branch) {
            this.setState({ branch: data.branch });
          }
        }
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      !this.props.publicController.roomDevice &&
      nextProps.publicController.roomDevice
    ) {
      this.subscribeToRoom(nextProps.publicController.roomDevice);
      this.setState({
        event: nextProps.publicController.roomDevice.room.current_event,
        promos: nextProps.publicController.roomDevice.room.promos
      });
    }

    if (
      !this.props.publicController.branch &&
      nextProps.publicController.branch
    ) {
      this.subscribeToBranch(nextProps.publicController.branch);
      this.setState({
        branch: nextProps.publicController.branch
      });
    }
  }

  render() {
    const roomDevice = this.props.publicController.roomDevice;
    const event = this.state.event;
    const branch = this.state.branch;
    const promos = this.state.promos;

    if (roomDevice) {
      if (event) {
        return <ScreenComponent event={event} promos={promos} />;
      }

      return (
        <Grid centered columns={2} style={Styles.emptyScreenContent}>
          <Grid.Column width={4}>
            <Card fluid style={Styles.emptyScreenCard}>
              <Card.Content style={{ textAlign: "center" }}>
                {roomDevice.room.company.logo_url && (
                  <Image
                    src={Images.remote(roomDevice.room.company.logo_url)}
                    style={Styles.emptyScreenLogo}
                  />
                )}
                <Item style={Styles.emptyScreenText}>
                  {roomDevice.room.name.toUpperCase()}
                </Item>
                <Image src={Images.logo} style={Styles.emptyScreenLogo} />
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
      );
    }

    if (branch) {
      return <BranchScreenComponent branch={branch} />;
    }

    return (
      <Dimmer inverted active>
        <Loader />
      </Dimmer>
    );
  }
}

module.exports = {
  development: {
    apiUrl: "http://127.0.0.1:3001",
    wsUrl: "http://127.0.0.1:3001/cable"
  },
  production: {
    apiUrl: "https://api.nitpoint.com",
    wsUrl: "wss://api.nitpoint.com/cable"
  }
};

import React, { Component } from "react";
import {
  Form,
  Grid,
  Segment,
  Icon,
  Button,
  Image,
  Item,
  Header,
  Confirm,
  Checkbox
} from "semantic-ui-react";
import Styles from "../Styles/LeaveMessageComponentStyles";
import { Images } from "../../themes";
import ErrorMessagesComponent from "./ErrorMessagesComponent";
import storage from "redux-persist/lib/storage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import queryString from "query-string";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import AppleLogin from "react-apple-login";

export default class LeaveMessageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: null,
      message: {
        name: "",
        email: ""
      },
      event: null,
      imagePreviewUrl: null,
      confirmOpen: false,
      acceptTerms: false,
    };
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);

    if (params.eventCode) {
      this.setState({ code: params.eventCode }, () => {
        this.props.findEventByCode(params.eventCode);
      });
    }

    if (params.claims) {
      const data = JSON.parse(atob(params.claims));

      this.props.setUser({
        name: data.email,
        email: data.email,
        provider_picture_url:
          "https://www.kindpng.com/picc/m/105-1055656_account-user-profile-avatar-avatar-user-profile-icon.png",
        provider_network: "apple"
      });
    }
  }

  async componentWillReceiveProps(nextProps) {
    if (
      !this.props.publicController.event &&
      nextProps.publicController.event
    ) {
      this.setState({ event: nextProps.publicController.event });

      let deviceId = await storage.getItem("device_id");

      if (!deviceId) {
        deviceId = nextProps.publicController.event.device_id;
        storage.setItem("device_id", deviceId);
      }

      this.setState({
        message: { ...this.state.message, device_id: deviceId }
      });
    }

    if (
      this.props.publicController.creatingMessage &&
      !nextProps.publicController.creatingMessage
    ) {
      if (nextProps.publicController.errors.length === 0) {
        toast.success("Mensaje enviado correctamente");

        this.setState({
          message: {
            ...this.state.message,
            content: "",
            image: null
          },
          imagePreviewUrl: null
        });

        document.querySelector('input[type="file"]').value = null;
      }
    }
  }

  handleChange(e, { name, value }) {
    this.setState({
      message: { ...this.state.message, [name]: value }
    });
  }

  handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        message: { ...this.state.message, image: file },
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
  }

  removeImage() {
    this.setState({
      message: { ...this.state.message },
      imagePreviewUrl: null
    });
    document.querySelector('input[type="file"]').value = null;
  }

  facebookResponse(facebookLogin) {
    this.setState({
      confirmOpen: false
    });

    if (!facebookLogin.accessToken) {
      return;
    }

    this.props.setUser({
      name: facebookLogin.name,
      email: facebookLogin.email,
      provider_user_id: facebookLogin.id,
      provider_access_token: facebookLogin.accessToken,
      provider_picture_url: facebookLogin.picture.data.url,
      provider_network: "facebook"
    });
  }

  googleResponse(googleLogin) {
    this.setState({
      confirmOpen: false
    });

    if (!googleLogin.accessToken) {
      return;
    }

    this.props.setUser({
      name: googleLogin.profileObj.name,
      email: googleLogin.profileObj.email,
      provider_user_id: googleLogin.profileObj.googleId,
      provider_access_token: googleLogin.accessToken,
      provider_picture_url: googleLogin.profileObj.imageUrl,
      provider_network: "google"
    });
  }

  render() {
    const event = this.props.publicController.event;
    const user = this.props.publicController.user;

    if (event) {
      return (
        <Grid
          textAlign="center"
          style={Styles.content}
          verticalAlign="middle"
          className="public"
        >
          <Grid.Column style={{ maxWidth: 400 }}>
            {user && user.email ? (
              <Form size="large" autoComplete="off">
                <Segment style={Styles.card}>
                  <div style={Styles.logosContainer}>
                    {event.company && (
                      <Image
                        src={Images.remote(event.company.logo_url)}
                        style={Styles.logos}
                      />
                    )}
                    <Image src={Images.logo} style={Styles.logos} />
                  </div>
                  <Form autoComplete="off">
                    <ErrorMessagesComponent
                      messages={this.props.publicController.errors}
                    />

                    <Item style={Styles.message}>
                      <Item style={Styles.messageTitle}>
                        <Item>Genial!</Item>
                      </Item>
                      <Item style={Styles.messageText}>
                        Has ingresado a la pantalla de {event.event_name}. Deja
                        tu mensaje y/o foto.
                      </Item>
                    </Item>

                    <div>
                      <div
                        className="public-label"
                        style={{ textAlign: "center" }}
                      >
                        {user.name}
                      </div>

                      <Image
                        src={user.provider_picture_url}
                        size="tiny"
                        circular
                        centered
                      />
                    </div>

                    {!event.image_only && (
                      <div style={{ marginBottom: 10 }}>
                        <div className="public-label">Mensaje</div>
                        <Form.TextArea
                          placeholder="(Opcional)"
                          name="content"
                          value={this.state.message.content}
                          onChange={this.handleChange.bind(this)}
                        />
                      </div>
                    )}

                    <Form.Field style={{ textAlign: "center" }}>
                      <label className="fileContainer">
                        <Icon name="camera" style={Styles.modalIcon} />
                        Añadir una foto
                        <input
                          type="file"
                          onChange={e => this.handleImageChange(e)}
                          accept="image/*"
                        />
                      </label>
                    </Form.Field>

                    {this.state.imagePreviewUrl && (
                      <div className="image-preview">
                        <Button
                          className="right floated"
                          content="Eliminar foto"
                          icon="trash"
                          labelPosition="left"
                          onClick={() => {
                            this.removeImage();
                          }}
                        />
                        {this.state.imagePreviewUrl && (
                          <Image
                            src={this.state.imagePreviewUrl}
                            size="medium"
                            centered
                          />
                        )}
                      </div>
                    )}

                    <p>
                      <Checkbox
                        checked={this.state.acceptTerms}
                        onChange={(e, input) => {
                          this.setState({...this.state, acceptTerms: input.checked })
                        }}
                        style={{ marginRight: 5 }}
                      />
                      Acepto los <a href="/terminos.pdf" target="_blank">términos y condiciones</a>, así como la <a href="/privacidad.pdf" target="_blank">política de privacidad</a> de la plataforma.
                    </p>

                    <Button
                      disabled={!this.state.acceptTerms}
                      loading={this.props.publicController.creatingMessage}
                      fluid
                      style={Styles.cardButton}
                      primary
                      onClick={() => {
                        this.props.createEventMessage(event.code, {
                          ...this.state.message,
                          ...user
                        });
                      }}
                    >
                      Enviar
                    </Button>

                    <Button
                      onClick={() => this.setState({ confirmOpen: true })}
                      style={Styles.textButton}
                    >
                      Volver al inicio
                    </Button>
                    <Confirm
                      size="small"
                      open={this.state.confirmOpen}
                      onCancel={() => this.setState({ confirmOpen: false })}
                      onConfirm={() => {
                        this.props.clearUser();
                        this.setState({ confirmOpen: false });
                      }}
                      cancelButton={
                        <Button style={Styles.cancelButton}>Cancelar</Button>
                      }
                      confirmButton={
                        <Button primary style={Styles.cardButton}>
                          Si
                        </Button>
                      }
                      content="¿Seguro desea desconectar su cuenta?"
                    />
                  </Form>
                </Segment>
              </Form>
            ) : (
              <Form size="large" autoComplete="off">
                <Segment style={Styles.card}>
                  <div style={Styles.logosContainer}>
                    {event.company && (
                      <Image
                        src={Images.remote(event.company.logo_url)}
                        style={Styles.logos}
                      />
                    )}
                    <Image src={Images.logo} style={Styles.logos} />
                  </div>
                  <Item style={Styles.message}>
                    <Item style={Styles.messageText}>
                      Ingresa con alguna de tus redes sociales
                    </Item>
                  </Item>
                  <Form autoComplete="off">
                    <FacebookLogin
                      autoLoad={false}
                      disableMobileRedirect={true}
                      appId="580990683171304"
                      fields="name,email,picture.width(200)"
                      callback={this.facebookResponse.bind(this)}
                      render={({ onClick, isProcessing }) => (
                        <Button
                          loading={isProcessing}
                          onClick={onClick}
                          fluid
                          style={Styles.cardButtonFacebook}
                          primary
                        >
                          <Icon name="facebook" style={Styles.buttonIcon} />
                          Inicia sesión con Facebook
                        </Button>
                      )}
                    />
                    <AppleLogin
                      clientId="com.codingways.nitpoint.web"
                      redirectURI="https://api.nitpoint.com/apple_auth"
                      state={this.state.code}
                      scope="name email"
                      responseMode="form_post"
                      render={({ onClick }) => (
                        <Button
                          onClick={onClick}
                          fluid
                          style={Styles.cardButtonApple}
                          primary
                        >
                          <Icon name="apple" style={Styles.buttonIcon} />
                          Inicia sesión con Apple
                        </Button>
                      )}
                    />
                    <GoogleLogin
                      clientId="944033839846-b00ef5h4885lbg47eq1utacc5tstttiq.apps.googleusercontent.com"
                      buttonText="Login"
                      onSuccess={this.googleResponse.bind(this)}
                      onFailure={this.googleResponse.bind(this)}
                      render={({ onClick }) => (
                        <Button
                          onClick={onClick}
                          fluid
                          style={Styles.cardButtonGoogle}
                          primary
                        >
                          <Icon name="google" style={Styles.buttonIcon} />
                          Inicia sesión con Google
                        </Button>
                      )}
                    />
                  </Form>
                  <Button
                    style={Styles.textButton}
                    onClick={this.props.clearEvent}
                  >
                    Volver al inicio
                  </Button>
                </Segment>
              </Form>
            )}

            <ToastContainer
              position={toast.POSITION.BOTTOM_RIGHT}
              closeButton={false}
            />
          </Grid.Column>
        </Grid>
      );
    }

    return (
      <Grid
        textAlign="center"
        style={Styles.content}
        verticalAlign="middle"
        className="public"
      >
        <Grid.Column style={{ maxWidth: 400 }}>
          <Form size="large" autoComplete="off">
            <Segment style={Styles.card}>
              <Header>
                <Image src={Images.logo} style={Styles.logo} />
              </Header>
              <Item style={Styles.message}>
                <Item style={Styles.messageTitle}>
                  <Item>Bienvenido a Nitpoint!</Item>
                </Item>
                <Item style={Styles.messageText}>
                  Ingresa el código en pantalla para dejar tu mensaje
                </Item>
              </Item>
              <Form autoComplete="off">
                <ErrorMessagesComponent
                  messages={this.props.publicController.errors}
                />

                <div className="public-label">Código</div>
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="right"
                  placeholder="Código"
                  name="code"
                  value={this.state.code}
                  onChange={(e, data) => {
                    this.setState({ code: data.value });
                  }}
                />

                <Button
                  loading={this.props.publicController.fetching}
                  fluid
                  style={Styles.cardButton}
                  primary
                  onClick={() => {
                    this.props.findEventByCode(this.state.code);
                  }}
                >
                  Ingresar
                </Button>
              </Form>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
}
